<template>
    <div>
    <!--Stats cards-->
    <div class="row" v-if="stats">
        <div class="col-lg-3 col-md-6 col-sm-6" v-for="(stats, index) in stats.cards" :key="index">
        <stats-card :type="stats.type"
                    :icon="stats.icon"
                    :small-title="stats.title"
                    :title="stats.value">
            <div class="stats update" slot="footer" @click="updateStats">
                <i :class="stats.footerIcon"></i>
                {{stats.footerText}}
            </div>
        </stats-card>
        </div>
    </div>

    <div class="row" v-if="false">
        <div class="col-lg-3 col-sm-6">
        <circle-chart-card :percentage="70"
                            title="Lead Generation"
                            description="Last Campaign Performance"
                            color="#4acccd">
            <template slot="footer">
            <div class="legend">
                <i class="fa fa-circle text-info"></i> Open
            </div>
            <hr>
            <div class="stats">
                <i class="fa fa-calendar"></i> Number of emails sent
            </div>
            </template>
        </circle-chart-card>
        </div>

        <div class="col-lg-3 col-sm-6">
        <circle-chart-card :percentage="34"
                            title="New Visitors"
                            description="Out Of Total Number"
                            color="#fcc468">
            <template slot="footer">
            <div class="legend">
                <i class="fa fa-circle text-warning"></i> Visited
            </div>
            <hr>
            <div class="stats">
                <i class="fa fa-check"></i> Campaign sent 2 days ago
            </div>
            </template>
        </circle-chart-card>
        </div>

        <div class="col-lg-3 col-sm-6">
        <circle-chart-card :percentage="80"
                            title="New Patients"
                            description="Total Number"
                            color="#f17e5d">
            <template slot="footer">
            <div class="legend">
                <i class="fa fa-circle text-danger"></i> Completed
            </div>
            <hr>
            <div class="stats">
                <i class="fa fa-clock-o"></i> Updated 3 minutes ago
            </div>
            </template>
        </circle-chart-card>
        </div>

        <div class="col-lg-3 col-sm-6">
        <circle-chart-card :percentage="11"
                            title="Non Returning"
                            description="Our Users"
                            color="#66615b">
            <template slot="footer">
            <div class="legend">
                <i class="fa fa-circle text-secondary"></i> Ended
            </div>
            <hr>
            <div class="stats">
                <i class="fa fa-history"></i> Total users
            </div>
            </template>
        </circle-chart-card>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12 card">
            <div class="card-body row">
                <div class="col-sm-3">
                    <fg-input name="doctor">
                        <el-select class="select-default-special" v-model="currentDoctor" placeholder="Select Doctor" @change="changeDoctor">
                            <el-option class="select-default" v-for="doctor in doctors" :key="doctor.id" :label="doctor.name" :value="doctor.id"></el-option>
                        </el-select>
                    </fg-input>
                    
                </div>
                <div class="col-sm-3">
                    <fg-input name="per-page">
                        <el-select class="select-default-special" v-model="pagination.per_page" placeholder="Per page">
                            <el-option class="select-default" v-for="item in perPageOptions" :key="item" :label="item" :value="item"></el-option>
                        </el-select>
                    </fg-input>
                </div>
                <div class="col-sm-6">
                    <div class="pull-right">
                        <fg-input
                        class="input-sm"
                        placeholder="Search"
                        v-model="searchQuery"
                        addon-right-icon="nc-icon nc-zoom-split"
                        ></fg-input>
                    </div>
                </div>
                <div class="col-sm-12 mt-2">
                <el-table class="table-striped" :data="tableData" border style="width: 100%;" height="600">
                    <el-table-column
                    v-for="(column, index) in tableColumns"
                    :key="column.label"
                    :min-width="column.minWidth"
                    :prop="column.prop"
                    :label="column.label"
                    :sortable="column.sortable || false"
                    :align="column.align"
                    :header-align="column.align"
                    v-if="!column.hidden"
                    :formatter="column.formatter || null"
                    :fixed="column.fixed || false"
                    ></el-table-column>

                    <el-table-column :min-width="150" fixed="right" class-name="td-actions" label="Actions">
                    <template slot-scope="props">
                        <!-- <p-button type="info" size="sm" icon @click="handleLike(props.$index, props.row)">
                        <i class="fa fa-eye"></i>
                        </p-button> -->
                        <p-button
                        type="success"
                        size="sm"
                        icon
                        @click="handleEdit(props.$index, props.row)"
                        >
                        <i class="fa fa-edit"></i>
                        </p-button>
                        <p-button
                        type="danger"
                        size="sm"
                        icon
                        @click="handleDelete(props.$index, props.row)"
                        >
                        <i class="fa fa-trash"></i>
                        </p-button>
                    </template>
                    </el-table-column>
                </el-table>
                </div>
                <div class="col-sm-6 pagination-info">
                <p class="category">Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.total }} entries</p>
                </div>
                <div class="col-sm-6">
                <p-pagination
                    class="pull-right"
                    v-model="pagination.current_page"
                    :per-page="Number.parseInt(pagination.per_page)"
                    :total="pagination.total"
                    @input="changePage()"
                ></p-pagination>
                </div>
            </div>
            </div>
    </div>

    <div class="row" v-if="false">
        <div class="col-lg-4 col-sm-6">
        <chart-card :chart-data="activeUsersChart.data" chart-title="TOTAL EARNINGS IN LAST TEN QUARTERS">
            <span slot="title">Rs. 34,657</span>
            <badge slot="title-label" type="success">+18%</badge>

            <template slot="footer-title">Financial Statistics</template>

            <p-button slot="footer-right" type="success" round icon size="sm">
            <i class="nc-icon nc-simple-add"></i>
            </p-button>
        </chart-card>
        </div>

        <div class="col-lg-4 col-sm-6">
        <chart-card :chart-data="emailsCampaignChart.data" color="#ef8156" chart-title="TOTAL CONVERSIONS IN LAST 7 DAYS">
            <span slot="title">169</span>
            <badge slot="title-label" type="danger">-14%</badge>

            <template slot="footer-title">View all members</template>

            <p-button slot="footer-right" type="danger" round icon size="sm">
            <i class="nc-icon nc-button-play"></i>
            </p-button>
        </chart-card>
        </div>

        <div class="col-lg-4 col-sm-6">
        <chart-card :chart-data="activeCountriesChart.data" color="#fbc658" chart-title="Active Patients">
            <span slot="title">8,960</span>
            <badge slot="title-label" type="warning">~51%</badge>

            <template slot="footer-title">View more details</template>

            <p-button slot="footer-right" type="warning" round icon size="sm">
            <i class="nc-icon nc-alert-circle-i"></i>
            </p-button>
        </chart-card>
        </div>
    </div>

    <!-- <div class="row">
        <div class="col-lg-12">
            <div class="card">
            <div class="card-header">
                <h4 class="card-title">Global Sales by Top Locations</h4>
                <p class="category">All products that were shipped</p>
            </div>
            <div class="card-body">
                <div class="row">
                <div class="col-md-7">
                    <world-map></world-map>
                </div>
                <div class="col-md-5">
                    <div class="table-responsive">
                    <table class="table">
                        <tbody>
                        <tr>
                        <td>
                            <div class="flag">
                            <img src="/static/img/flags/US.png">
                            </div>
                        </td>
                        <td>USA</td>
                        <td class="text-right">
                            2.920
                        </td>
                        <td class="text-right">
                            53.23%
                        </td>
                        </tr>
                        <tr>
                        <td>
                            <div class="flag">
                            <img src="/static/img/flags/DE.png">
                            </div>
                        </td>
                        <td>Germany</td>
                        <td class="text-right">
                            1.300
                        </td>
                        <td class="text-right">
                            20.43%
                        </td>
                        </tr>
                        <tr>
                        <td>
                            <div class="flag">
                            <img src="/static/img/flags/AU.png">
                            </div>
                        </td>
                        <td>Australia</td>
                        <td class="text-right">
                            760
                        </td>
                        <td class="text-right">
                            10.35%
                        </td>
                        </tr>
                        <tr>
                        <td>
                            <div class="flag">
                            <img src="/static/img/flags/GB.png">
                            </div>
                        </td>
                        <td>United Kingdom</td>
                        <td class="text-right">
                            690
                        </td>
                        <td class="text-right">
                            7.87%
                        </td>
                        </tr>
                        <tr>
                        <td>
                            <div class="flag">
                            <img src="/static/img/flags/RO.png">
                            </div>
                        </td>
                        <td>Romania</td>
                        <td class="text-right">
                            600
                        </td>
                        <td class="text-right">
                            5.94%
                        </td>
                        </tr>
                        <tr>
                        <td>
                            <div class="flag">
                            <img src="/static/img/flags/BR.png">
                            </div>
                        </td>
                        <td>Brasil</td>
                        <td class="text-right">
                            550
                        </td>
                        <td class="text-right">
                            4.34%
                        </td>
                        </tr>
                        </tbody>
                    </table>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
        <task-list></task-list>
        </div>
        <div class="col-md-6">
        <chart-card :chart-data="activityChart.data"
                    :chart-height="300"
                    chart-type="Bar">
            <template slot="header">
            <h4 class="card-title">2018 Sales</h4>
            <p class="card-category">All products including Taxes</p>
            </template>
            <template slot="footer">
            <div class="legend">
                <i class="fa fa-circle text-info"></i> Tesla Model S
                <i class="fa fa-circle text-danger"></i> BMW 5 Series
            </div>
            <hr>
            <div class="stats">
                <i class="fa fa-check"></i> Data information certified
            </div>
            </template>
        </chart-card>
        </div>
    </div> -->

    

    </div>
</template>
<script>
    import Vue from "vue";
    import CircleChartCard from 'src/components/UIComponents/Cards/CircleChartCard.vue'
    import ChartCard from 'src/components/UIComponents/Cards/ChartCard';
    import StatsCard from 'src/components/UIComponents/Cards/StatsCard';
    import {Badge} from 'src/components/UIComponents'
    import Loading from 'src/components/Dashboard/Layout/LoadingMainPanel.vue'
    import TaskList from './Widgets/TaskList'

    import { Table, TableColumn, Select, Option } from "element-ui";
    import PPagination from "src/components/UIComponents/Pagination.vue";

    Vue.use(Table);
    Vue.use(TableColumn);
    Vue.use(Select);
    Vue.use(Option);

    import { mapGetters } from "vuex"

    const WorldMap = () => ({
        component: import('./../Maps/WorldMap.vue'),
        loading: Loading,
        delay: 200
    })

    export default {
        components: {
            StatsCard,
            ChartCard,
            CircleChartCard,
            WorldMap,
            Badge,
            TaskList,
            PPagination,
        },
        computed: {
            ...mapGetters({
                patients: "getPatients",
                stats: "getStats",
                doctors: "getDoctors",
                tableData: "getAppointments",
                pagination: "getPagination"
            }), 
        },
        mounted() {
            this.$store.dispatch("fetchSimplePatientsAll");
            this.$store.dispatch("fetchStats");
            this.$store.dispatch("fetchDoctorsAll");
            this.$store.dispatch("fetchAppointments", { perPage: 10, page: 1 });
        },
        watch: {
            searchQuery: function (val) {
                this.$store.dispatch("fetchAppointments", {
                    searchQuery: this.searchQuery,
                    per_page: this.pagination.per_page,
                    page: this.pagination.current_page
                }); 
            }
        },
        /**
         * Chart data used to render stats, charts. Should be replaced with server data
         */
        data () {
            return {
                currentDoctor: null,
                activeUsersChart: {
                    data: {
                        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct"],
                        series: [542, 480, 430, 550, 530, 453, 380, 434, 568, 610]
                    }
                },
                emailsCampaignChart: {
                    data: {
                        labels: ["12pm", "3pm", "6pm", "9pm", "12am", "3am", "6am", "9am"],
                        series: [40, 500, 650, 700, 1200, 1250, 1300, 1900]
                    }
                },
                activeCountriesChart: {
                    data: {
                        labels: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October"],
                        series: [80, 78, 86, 96, 83, 85, 76, 75, 88, 90]
                    }
                },
                activityChart: {
                    data: {
                        labels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
                        datasets: [
                            {
                                label: "Data",
                                borderColor: '#fcc468',
                                fill: true,
                                backgroundColor: '#fcc468',
                                hoverBorderColor: '#fcc468',
                                borderWidth: 8,
                                data: [100, 120, 80, 100, 90, 130, 110, 100, 80, 110, 130, 140, 130, 120, 130, 80, 100, 90, 120, 130],
                            },
                            {
                                label: "Data",
                                borderColor: '#4cbdd7',
                                fill: true,
                                backgroundColor: '#4cbdd7',
                                hoverBorderColor: '#4cbdd7',
                                borderWidth: 8,
                                data: [80, 140, 50, 120, 50, 150, 60, 130, 50, 130, 150, 100, 110, 80, 140, 50, 140, 50, 110, 150],
                            }
                        ]
                    }
                },
                perPageOptions: ["10", "30", "50", "100", "250", "500"],
                searchQuery: "",
                propsToSearch: [
                    "patient_id",
                    "patient_name",
                    "location_id",
                    "location_name",
                    "doctor_id",
                    "doctor_name",
                    "appointment_type",
                    "datetime",
                    "note",
                ],
                tableColumns: [
                    {
                    prop: "id",
                    label: "ID",
                    minWidth: 50,
                    hidden: true,
                    align: "center"
                    },
                    {
                    prop: "appointment_id",
                    label: "Appt ID",
                    minWidth: 150,
                    fixed: true,
                    sortable: true,
                    align: "center"
                    },
                    {
                    prop: "location.location_id",
                    label: "LOC ID",
                    minWidth: 150,
                    fixed: false,
                    sortable: true,
                    align: "center"
                    },
                    {
                    prop: "location.name",
                    label: "Location Name",
                    minWidth: 250,
                    sortable: true,
                    align: "left"
                    },
                    {
                    prop: "patient.patient_id",
                    label: "Pat ID",
                    minWidth: 150,
                    fixed: false,
                    sortable: true,
                    align: "center"
                    },
                    {
                    prop: "patient.name",
                    label: "Patient Name",
                    minWidth: 250,
                    sortable: true,
                    align: "left"
                    },
                    {
                    prop: "doctor.employee_id",
                    label: "Doc ID",
                    minWidth: 150,
                    fixed: false,
                    sortable: true,
                    align: "center"
                    },
                    {
                    prop: "doctor.name",
                    label: "Doctor Name",
                    minWidth: 250,
                    sortable: true,
                    align: "left"
                    },
                    {
                    prop: "appointment_type",
                    label: "Type",
                    minWidth: 200,
                    align: "center"
                    },
                    {
                    prop: "datetime",
                    label: "Date & Time",
                    minWidth: 300,
                    align: "center",
                    formatter: function(row, column, cellValue, index){
                        var d = new Date(row.datetime);
                        var options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }
                        return  d.toLocaleDateString('en-US', options); 
                    }
                    },
                    {
                    prop: "note",
                    label: "Note",
                    minWidth: 250,
                    align: "left"
                    }
                ],

            }
        },

        methods: {
            updateStats(){
                this.$store.dispatch("fetchStats");
                this.$notification.show('Zyro ERP', {
                    title: 'Update!',
                    body: 'System Stats updated successfully!',
                    icon: '/static/img/zyro-logo.png',
                    badge: '/static/img/zyro-logo.png'
                }, {})
            },
            changeDoctor(){
                this.$store.dispatch("fetchAppointmentsByDoctor", { doctor: this.currentDoctor, per_page : this.pagination.per_page, page : 1, searchQuery : this.searchQuery });
            },
            changePerPage(){
                this.$store.dispatch("fetchAppointments", { per_page : this.pagination.per_page, page : 1, searchQuery : this.searchQuery});
            },
            changePage(){
                this.$store.dispatch("fetchAppointments", { per_page : this.pagination.per_page, page : this.pagination.current_page, searchQuery : this.searchQuery});
            },
            handleLike(index, row) {
                // alert(`Your want to like ${row.name}`);
                // this.$router.push(`/doctors/view/${row.id}`)
            },
            handleEdit(index, row) {
                this.$router.push(`/appointments/manage/${row.id}`)
            },
            handleDelete(index, row) {
                this.$store.dispatch('deleteAppointment', row.id)
            }
        }
    }

</script>
<style>
.stats.update{
    cursor: pointer;
}

.stats.update i{
    transition: all ease 1s;
}

.stats.update:hover i{
    transform: rotate(360deg);
}

.el-table__fixed-body-wrapper,
.el-table__fixed-header-wrapper{
    background: white;
}
</style>
